import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import Img from "gatsby-image"

// Import Swiper styles
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

export default ({ images }) => {
  SwiperCore.use([Autoplay])

  const image = targetName => images.find(({ name }) => name === targetName)
  const imageFluid = targetName => image(targetName).childImageSharp.fluid

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={"auto"}
      centeredSlides={true}
      autoplay={{ delay: 5000 }}
      loop={true}
      className="custom-swiper-container"
    >
      <SwiperSlide>
        <Img
          fluid={imageFluid("billboard-2")}
          className="hero-image"
          alt="Billboard with #weremember sign"
        />
      </SwiperSlide>

      <SwiperSlide>
        <Img
          fluid={imageFluid("eu-2")}
          className="hero-image"
          alt="Woman holding #weremember sign"
        />
      </SwiperSlide>
      <SwiperSlide>
        <Img
          fluid={imageFluid("football-team-2")}
          className="origin-right hero-image"
          alt="Football players with #weremember sign"
        />
      </SwiperSlide>
      <SwiperSlide>
        <Img
          fluid={imageFluid("tower-2")}
          className="origin-right hero-image"
          alt="Tower with #weremember sign"
        />
      </SwiperSlide>
      <SwiperSlide>
        <Img
          fluid={imageFluid("football-game-2")}
          className="origin-right hero-image"
          alt="Football players on game with #weremember sign"
        />
      </SwiperSlide>
      <SwiperSlide>
        <Img
          fluid={imageFluid("building-2")}
          className="md:origin-right xl:origin-center hero-image"
          alt="Building with #weremember sign"
        />
      </SwiperSlide>
      <SwiperSlide>
        <Img
          fluid={imageFluid("pope-2")}
          className="origin-right hero-image"
          alt="The Pope holding #weremember sign"
        />
      </SwiperSlide>
      <SwiperSlide>
        <Img
          fluid={imageFluid("square-2")}
          className="origin-right hero-image"
          alt="Public building with #weremember sign"
        />
      </SwiperSlide>
      <SwiperSlide>
        <Img
          fluid={imageFluid("wonder-woman-2")}
          className="origin-right hero-image"
          alt="Actress with #weremember sign"
        />
      </SwiperSlide>
    </Swiper>
  )
}
